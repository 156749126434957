<template>
  <b-card
    :title="type === 'add' ? 'Подключение Email' : 'Редактировать Email'"
    class="col col-md-6"
    v-if="type === 'add' || getCurrentIntegration"
  >
    <b-row>
      <b-col md="12" class="mb-2">
        <label for="webhook-name"> Название: </label>
        <b-form-input
          placeholder="Введите название"
          id="webhook-name"
          v-model="email.name"
        >
        </b-form-input>
      </b-col>
      <b-col md="12" class="mb-2">
        <label for="webhook-url"> Email для отправки: </label>
        <b-form-input
          placeholder="Введите URL"
          id="webhook-url"
          v-model="email.Clientmail"
        >
        </b-form-input>
      </b-col>
      <b-col>
        <b-button
          variant="primary"
          @click="type === 'add' ? emailAdd() : emailedit()"
        >
          {{ type === "add" ? "Подключить Email" : "Редактировать" }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
  },
  data() {
    return {
      email: {
        type: "Email",
        Clientmail: JSON.parse(localStorage.getItem("userData")).user.email,
        name: "",
      },
      type: "add",
    };
  },
  metaInfo() {
    return {
      title: "moovee - Email интеграция",
    };
  },
  computed: {
    ...mapGetters("integrations", ["getCurrentIntegration"]),
  },
  watch: {
    getCurrentIntegration(newVal) {
      this.webhook = newVal;
    },
  },
  methods: {
    ...mapActions("integrations", [
      "addIntegration",
      "requestCurrentIntegration",
      "editIntegration",
    ]),
    emailAdd() {
      this.addIntegration(this.email).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            variant: "success",
            title: "Email подключен",
            icon: "BellIcon",
          },
        });
        this.$router.push({ name: "integrations" });
      });
    },
    emailEdit() {
      this.editIntegration({
        id: this.$route.params.id,
        data: this.email,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            variant: "success",
            title: "Email редактирован",
            icon: "BellIcon",
          },
        });
        this.$router.push({ name: "integrations" });
      });
    },
  },
  created() {
    console.log(JSON.parse(localStorage.getItem("userData")));
    if (this.$route.params.id !== "add") {
      this.type = "edit";
      this.requestCurrentIntegration(this.$route.params.id);
    }
  },
};
</script>
